import {FormControl, MenuItem, Select, SelectChangeEvent} from "@mui/material";

import './IconSelect.component.scss';

interface IconSelectItem {
  label: string;
  value: any;
  disabled?: boolean,
}

interface IconSelectProps {
  value: any;
  onChange: (event: SelectChangeEvent) => void;
  label: string;
  icon: JSX.Element;
  items: IconSelectItem[],
  name?: string,
}

export const IconSelect = ({ value, onChange, label, icon, items, name }: IconSelectProps) => {
  const inputProps = {
    'aria-label': label,
  };

  const menuProps = {
    className: 'icon-select__menu',
  };

  const menuItems = items.map(({ label, value, disabled }) => (
    <MenuItem key={value} value={value} disabled={disabled as boolean}>{label}</MenuItem>
  ));

  return (
    <div className="icon-select">
      <FormControl variant="outlined">
        <Select
          name={name || ''}
          value={value}
          onChange={onChange}
          displayEmpty
          inputProps={inputProps}
          MenuProps={menuProps}
        >
          <MenuItem className="icon-select__placeholder-option" value="">
            <div className="icon-select__icon">
              {icon}
            </div>
            <strong>{label}</strong>
          </MenuItem>
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
}