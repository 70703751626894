import {useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";

export const RegionPathRedirect = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    navigate('/', {
      state: {
        pathname,
      },
    });
  }, [navigate, pathname]);

  return null;
}
