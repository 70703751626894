import { Route, Routes } from 'react-router-dom';
import App from '../../../App';
import {SelectRegionPage} from "../../modules/region/pages/SelectRegion.page";
import {RegionPathRedirect} from "../components/RegionPathRedirect.component";

export const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route index element={<SelectRegionPage />} />
        <Route path="*" element={<RegionPathRedirect />} />
      </Route>
    </Routes>
  );
};
