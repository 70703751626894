interface Region {
    label: string,
    name: string,
    shortname: string,
    url: string,
    disabled: boolean
}

export interface Regions {
    // northcentralus: Region,
    westeurope: Region,
    brazilsouth: Region,
}

export const RegionConstants: Regions = {
    brazilsouth: {
        label: 'brazilsouth',
        name: 'Brazil South',
        shortname: 'Brazil South',
        url: 'https://south-br.admin.flexxanalyzer.com',
        disabled: false
    },
    // northcentralus: {
    //     label: 'northcentralus',
    //     name: 'North Central US',
    //     shortname: 'North Central US',
    //     url: 'https://northcentral-us.admin.flexxanalyzer.com',
    //     disabled: false
    // },
    westeurope: {
        label: 'westeurope',
        name: 'Western Europe',
        shortname: 'West EU',
        url: 'https://west-eu.admin.flexxanalyzer.com',
        disabled: false
    },
};
