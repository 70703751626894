import {useCallback, useEffect} from "react";
import {useCookies} from "react-cookie";
import {SelectChangeEvent} from "@mui/material";
import {LocationOn} from "@mui/icons-material";
import {RegionSelector} from "../../../infraestructure/components/region-selector/RegionSelector.component";
import {IconSelect} from "../../../infraestructure/components/icon-selector/IconSelect.component";
import {RegionConstants, Regions} from "../../../infraestructure/Region.constants";
import {useLocation, useSearchParams} from "react-router-dom";
import { SelectRegionConfig } from './resources/SelectRegion.config';

const FLEXXANALYZER_REGION_COOKIE = 'flexxAnalyzerCookie';
const FLEXXANALYZER_REGION_QUERY_PARAM = 'redirect';

interface SelectRegionLocationState {
  pathname: string;
}

type SelectRegionLocationStateType = SelectRegionLocationState | null;

export const SelectRegionFeature = () => {
  const [cookies, setCookie, removeCookie] = useCookies([FLEXXANALYZER_REGION_COOKIE]);
  const [searchParams] = useSearchParams();
  const clearCookie = searchParams.get(FLEXXANALYZER_REGION_QUERY_PARAM);

  const location = useLocation();
  const state = location.state as SelectRegionLocationStateType;
  const pathname = state ? state.pathname : '';

  const items = Object.entries(RegionConstants).map(([ , region ]) => ({
    label: region.name,
    value: region.label,
    disabled: region.disabled,
  }));

  const handleChangeRegion = ({ target: { value } }: SelectChangeEvent) => {
    setCookie(FLEXXANALYZER_REGION_COOKIE, RegionConstants[value as keyof Regions].label, SelectRegionConfig);

    const regionUrl = RegionConstants[value as keyof Regions].url;
    if (regionUrl) {
      window.location.href = `${regionUrl}${pathname}`;
    }
  };

  const loginRedirection = useCallback(() => {
    const regionUrl = RegionConstants[cookies.flexxAnalyzerCookie as keyof Regions].url;
    if (regionUrl) {
      window.location.href = `${regionUrl}${pathname}`;
    }
  }, [cookies.flexxAnalyzerCookie, pathname]);

  useEffect(() => {
    if (clearCookie) {
      removeCookie(FLEXXANALYZER_REGION_COOKIE);
    }
  }, [clearCookie, removeCookie]);

  useEffect(() => {
    if(cookies.flexxAnalyzerCookie && !clearCookie) {
      loginRedirection();
    }
  }, [cookies.flexxAnalyzerCookie, clearCookie, loginRedirection]);

  return (
      <RegionSelector>
        <IconSelect
          value=""
          onChange={handleChangeRegion}
          label="Select your region"
          icon={<LocationOn />}
          items={items}
        />
      </RegionSelector>
  );
}
