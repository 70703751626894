import backgroundImage from "../../../assets/img/background-flexxAnalyzer.jpg";
import selectRegionMap from "../../../assets/img/map.png";
import LogoFlexxAnalyzerNeg from "../../../assets/img/FlexxANALYZER_neg.svg";
import LogoFlexxible from "../../../assets/img/Flexxible_negative.svg";
import {ReactNode} from "react";

import './RegionSelector.component.scss';

interface RegionSelectorProps {
  children: ReactNode
}

export const RegionSelector = ({ children }: RegionSelectorProps) => {
  return (
    <main className="fa-login" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="fa-login__wrapper">
        <section className="fa-login__card">
          <img src={LogoFlexxAnalyzerNeg} alt="FlexxAnalyzer" className="fa-login__card__logo" width="306px" />
          <hr className="fa-login__card__divider" />
          <div className="fa-login__card__select-region" style={{ backgroundImage: `url(${selectRegionMap})` }}>
            {children}
          </div>
        </section>
        <div className="fa-login__card-shadow" />
      </div>
      <section className="fa-login__footer">
        <img src={LogoFlexxible} alt="FlexxibleIT" className="fa-login__footer__logo" width="174px" />
      </section>
    </main>
  );
}
